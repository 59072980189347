<template>
  <div>
    <el-dialog
      title="账户登录"
      :append-to-body="false"
      width="440px"
      :close-on-click-modal="true"
      :show-close="false"
      center
      align-center
      v-model="dialog">
      <el-form
        :rule="formRules"
        :model="form"
        ref="formRef"
        label-width="auto">
        <el-form-item
          prop="user_phone"
          :rules="formRules.mobile">
          <el-input
            v-model="form.user_phone"
            @input="form.user_phone = form.user_phone.replace(/[^\d]/g, '')"
            maxlength="11"
            placeholder="请输入手机号"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item
          prop="captcha_code"
          :rules="formRules.captchaCode">
          <el-input
            v-model="form.captcha_code"
            maxlength="4"
            placeholder="请输入图形验证码"
            clearable>
          </el-input>
          <div class="captcha" @click="fetchCaptcha" v-if="objCaptcha.url">
            <img :src="'data:image/png;base64,' + objCaptcha.url" alt="图形验证码">
          </div>
        </el-form-item>
        <el-form-item
          prop="code"
          :rules="formRules.code">
          <el-row style="width: 100%;">
            <el-col :span="16" style="padding-right: 5px;">
              <el-input
                v-model="form.code"
                @input="form.code = form.code.replace(/[^\d]/g, '')"
                maxlength="6"
                placeholder="请输入验证码"
                clearable>
              </el-input>
            </el-col>
            <el-col :span="8" style="padding-left: 5px;">
              <el-button
                class="code"
                type="primary"
                @click="funGetCode">
                {{ [0, 60].includes(numCount) ? '获取验证码' : numCount + 's后重发' }}
              </el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
          <el-button class="login" type="primary" :disabled="booLock" @click="handleSubmit">登录</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script setup>
import { useCommonStore } from '~/store'

const { $token, $userPhone } = useNuxtApp()
const commonStore = useCommonStore()
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
    required: true
  }
})
const emits = defineEmits(['update:modelValue'])
const dialog = ref(!!props.modelValue)
const formRef = ref('')
const form = reactive({
  user_phone: '',
  code: '',
  captcha_code: ''
})
const booLock = ref(false)
let numCount = ref(60)
let timer = null
let objCaptcha = ref({})

// 图形验证码
async function fetchCaptcha() {
  const data = await $http('/api/home/visa/captcha?ts=' + new Date().getTime(), {}, 'get')
  if (data.code === 200) {
    objCaptcha.value = Object.prototype.toString.call(data.data) === '[object Object]' ? data.data : {}
  }
}

// 获取验证码
function funGetCode() {
  if (numCount.value < 60 && numCount.value > 0) {
    return
  }
  formRef.value.validateField(['user_phone', 'captcha_code'], async (valid) => {
    if (valid) {
      numCount.value = 60
      funCutDown()
      const data = await $http('/api/home/visa/send/code', {
        'captcha_code': form.captcha_code,
        'captcha_key': objCaptcha.value.key,
        'user_phone': form.user_phone
      }, 'get')
      if (data.code === 200) {
      } else {
        clearInterval(timer)
        numCount.value = 60
        fetchCaptcha()
      }
    }
  })
}

function funCutDown() {
  clearInterval(timer)
  timer = setInterval(() => {
    if (numCount.value === 0) {
      clearInterval(timer)
      return
    }
    numCount.value--
  }, 1000)
}

function handleSubmit() {
  formRef.value.validate(async (valid) => {
    if (valid) {
      const postData = {
        'user_phone': form.user_phone,
        'code': form.code
      }
      delete postData.captcha_code

      booLock.value = true
      const data = await $http('/api/home/visa/user/login', postData)
      if (data.code === 200) {
        if (Object.prototype.toString.call(data.data) === '[object Object]' && Object.prototype.hasOwnProperty.call(data.data, 'user_token')) {
          $token.value = data.data.user_token
          $userPhone.value = form.user_phone
          commonStore.token = data.data.user_token
          commonStore.userPhone = form.user_phone
          dialog.value = false

          setTimeout(() => {
            location.reload()
          }, 200)
        } else {
          ElMessage.error(data.msg || '出错了')
        }
      }
      booLock.value = false
    }
  })
}

onMounted(async () => {
  if ($userPhone.value) {
    form.user_phone = $userPhone.value
  }
  fetchCaptcha()
})

onBeforeUnmount(() => {
  clearInterval(timer)
})

watch(dialog, (val) => {
  if (!val) {
    emits('update:modelValue', val)
  }
})
</script>

<style scoped lang="scss">
:deep(.el-dialog) {
  width: 440px;
  box-shadow: 0 2px 32px 0 rgba(0, 0, 0, 0.5);
  border-radius: 16px;

  .el-dialog__header {
    font-size: 16px;
    color: #000000;
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #E1E1E1;
  }

  .el-form-item {
    width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  .el-input-group__append, .el-input-group__prepend {
    background-color: transparent;
    border-right: none;
  }

  .el-form-item__content {
    position: relative;
    left: 0;
    top: 0;

    .el-input__wrapper {
      background-color: #F6F6F6;

      &.is-focus {
        box-shadow: none;
      }
    }
  }

  .el-form-item.is-error .el-input__wrapper {
    box-shadow: 0 0 0 1px #e93324 inset;
  }

  .el-form-item__error {
    color: #e93324;
  }

  .captcha {
    position: absolute;
    right: 1px;
    top: 1px;
    bottom: 1px;
    z-index: 1;
    cursor: pointer;

    img {
      display: block;
      height: 100%;
      border-radius: 0 4px 4px 0;
      overflow: hidden;
    }
  }

  .el-button.code,
  .el-button.login {
    border-radius: 4px;
    border: none;
    background-color: #e93324;
  }

  .el-button.code {
    width: 100%;
  }

  .el-button.login {
    width: 208px;
    height: 36px;
    margin: 32px auto 0;
  }
}
</style>
