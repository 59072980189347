<template>
  <div class="layout-container flex flex-col">
    <div class="account-box">
      <el-button
        class="login"
        @click="login"
        v-if="!token">
        <img class="login-icon" src="/img/common/login@2x.png">
        登录
      </el-button>
      <el-button
        class="logout"
        v-if="!!token"
        @click="logout">
        {{ userPhone }}
        <img class="logout-icon" src="/img/common/logout@2x.png">
      </el-button>
    </div>
    <div class="flex-1">
      <div>
        <div class="header flex justify-center items-center">
          <img src="/img/common/logo.svg" alt="">
          <p>夫为因私出入境服务中心</p>
        </div>
        <!-- 顶部导航栏 -->
        <el-tabs
          v-model="activeName" @tab-click="handleClick">
          <el-tab-pane name="/country">
            <template #label>
              <img src="/img/common/ic_country.svg" alt="">
              <p
                class="base"
                :class="currentTab === '/country' ? 'is-active' : ''">了解目的地</p>
            </template>
          </el-tab-pane>
          <el-tab-pane name="/contact">
            <template #label>
              <img src="/img/common/ic_contact.svg" alt="">
              <p
                class="base"
                :class="currentTab === '/contact' ? 'is-active' : ''">联系我们</p>
            </template>
          </el-tab-pane>
          <el-tab-pane name="/about">
            <template #label>
              <img src="/img/common/ic_about.svg" alt="">
              <p
                class="base"
                :class="currentTab === '/about' ? 'is-active' : ''">关于我们</p>
            </template>
          </el-tab-pane>
          <el-tab-pane name="/price" v-if="false">
            <template #label>
              <img src="/img/common/ic_price.svg" alt="">
              <p
                class="base"
                :class="currentTab === '/price' ? 'is-active' : ''">查看报价</p>
            </template>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 页面内容区 -->
      <slot></slot>
    </div>
    <!-- 页脚 -->
    <div class="footer flex flex-col justify-center items-center">
      <client-only>
        <p class="font-semibold">{{ webConfig.config_web_zhuti }}</p>
        <p class="font-semibold">联系电话：{{ webConfig.config_phone }} 地址：{{ webConfig.config_address }}</p>
        <p>
          <NuxtLink
            target="_blank"
            :to="'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010602007540'">
            <img
              style="float:left;width: 20px;height: 20px;"
              src="/img/common/beian.png"
              alt="">
            <p class="font-semibold" style="float:left;">沪公网备31010602007540号</p>
          </NuxtLink>
          <NuxtLink
            target="_blank"
            :to="'https://beian.miit.gov.cn'">
            <p class="font-semibold" style="float:left;margin-left: 10px;">{{ webConfig.config_web_beian }}</p>
          </NuxtLink>
        </p>
      </client-only>
    </div>

    <Login
      v-if="loginDialog.show"
      v-model="loginDialog.show"/>
  </div>
</template>

<script setup>
import { useCommonStore } from '~/store'

const router = useRouter()
const commonStore = useCommonStore()
const webConfig = commonStore.webConfig
const activeName = ref('/country')
const fullPath = computed(() => useRoute().fullPath)
const currentTab = ref('')
const loginDialog = reactive({
  show: false,
  exData: {}
})
const token = computed(() => commonStore.token)
const userPhone = computed(() => commonStore.userPhone)

function handleClick(value) {
  const { name } = value.props
  switch (name) {
    case '/contact':
      router.replace({ path: '/contact' })
      break
    case '/about':
      router.replace({ path: '/about' })
      break
    case '/price':
      router.replace({ path: '/price' })
      break
    default:
      router.replace({ path: '/country' })
  }
}

function login() {
  loginDialog.show = true
}

async function logout() {
  await commonStore.logout()

  // location.replace('/')
  location.reload()
}

onMounted(() => {
  if (/^\/country/.test(fullPath.value)) {
    currentTab.value = '/country'
  } else {
    currentTab.value = fullPath.value
  }
})
</script>


<style lang="scss" scoped>
.account-box {
  position: absolute;
  right: 26px;
  top: 40px;
  z-index: 10;

  img.login-icon {
    width: 22px;
    height: 17px;
    margin-right: 6px;
  }

  img.logout-icon {
    width: 17px;
    height: 17px;
    margin-left: 12px;
  }

  img.avatar {
    width: 26px;
    height: 26px;
    border: 1px solid #e93324;
    border-radius: 50%;
    margin-right: 2px;
    overflow: hidden;
    object-fit: contain;
  }

  :deep(.login.el-button) {
    width: 100px;
    height: 40px;
    background: #FFF7F3;
    border-radius: 5px;
    border: 1px solid #FFD7D7;

    span {
      color: #e93324;
    }
  }

  :deep(.logout.el-button) {
    border: none;
    background: transparent;

    span {
      color: #000000;
    }
  }
}

.layout-container {
  position: relative;
  left: 0;
  top: 0;
  min-height: 100vh;
  background: #FFF;
}

.header {
  padding: 28px 0;

  p {
    font-size: 30px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    line-height: 1.4;
    color: #313131;
  }

  img {
    width: 58px;
    height: 58px;
    margin-right: 6px;
  }
}

:deep(.el-tabs) {
  background-color: #f5f5f5;

  .el-tabs__header {
    margin-bottom: 0;
  }

  .el-tabs__nav-wrap {
    padding: 9px 0;

    &::after {
      opacity: 0;
    }

    .el-tabs__nav {
      float: none;
      justify-content: center;
    }

    .el-tabs__active-bar {
      height: 4px;
      background: #e93324;
      border-radius: 2px;
      visibility: hidden;
    }

    .el-tabs__item {
      padding: 0 65px;
      color: #000;
      overflow: hidden;

      p.base {
        position: relative;
        left: 0;
        top: 0;

        &.is-active {
          &::after {
            position: absolute;
            left: 50%;
            bottom: -6px;
            display: block;
            content: '';
            width: 19px;
            height: 4px;
            background: #e93324;
            border-radius: 2px;
            transform: translateX(-50%);
          }
        }
      }

      img {
        width: 12px;
        height: 15px;
        margin-right: 4px;
        filter: drop-shadow(#e93324 100px 0);
        transform: translateX(-100px);
      }
    }
  }
}

.footer {
  padding: 8px 0;
  color: #989898;
  background-color: #f5f5f5;

  p {
    font-size: 12px;
  }
}
</style>
